<template>
  <div style="text-align: center">
    <div style="height: 10vh; margin-top: 20px">
      <el-row type="flex" justify="center">
        <el-col :span="6">
          <el-button :type="type == 1 ? 'primary' : 'info'" @click="changeType(1)">创建房间</el-button>
        </el-col>
        <el-col :span="6">
          <el-button :type="type == 0 ? 'primary' : 'info'" @click="changeType(0)">进入房间</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="height: 45vh">
      <div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="房间名称">
            <el-input v-model="form.tvrName" maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="房间密码">
            <el-input v-model="form.tvrPwd" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="视频地址" v-if="type == 1">
            <el-input v-model="form.tvrUrl"></el-input>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="center">
          <el-col :span="6">
            <el-button type="primary" v-if="type == 1" @click="createRoom()">创建房间</el-button>
            <el-button type="primary" v-else @click="inRoom()">进入房间</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import tvRoom_Index from "../../../request/expand/tvRoom/index";
import cryptoUtil from "@/utils/cryptoUtil";

export default {
  name: "tvRoom",
  data() {
    return {
      ask: new tvRoom_Index(),
      type: 0,
      form: {
        tvrName: '',
        tvrPwd: '',
        tvrUrl: ''
      }
    }
  },
  mounted() {
    if (this.$route.query.type === 'pv') {
      if (!this.$store.state.user.isLogin) {
        this.$message({
          showClose: true,
          message: '错了哦，需要登录才能进入房间',
          type: 'error'
        });
        return false;
      }
      this.ask.getTvRoomDecrypt({
        "tvrName": this.$route.query.tvrName
      }).then(res => {
        this.$confirm('自动进入PV房间, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            name: 'onLineCinema',
            query: {
              vid:  this.$route.query.tvrName
            }
          })
        })
      })
    }
  },
  methods: {
    changeType(item) {
      this.type = item
    },
    createRoom() {
      this.ask.createRoom(this.form).then(res => {
        this.$message('创建成功');
      })
    },
    inOnLineCinema(res) {
      let encryptName = new cryptoUtil().encryptFun(res.tvrName)
      this.$router.push({
        name: 'onLineCinema',
        query: {
          vid: encryptName
        }
      })
    },
    inRoom() {
      if (this.$store.state.user.userInfo == null) {
        this.$message({
          showClose: true,
          message: '错了哦，需要登录才能进入房间',
          type: 'error'
        });
        return false;
      }
      this.ask.getRoomByName({tvrName: this.form.tvrName, tvrPwd: this.form.tvrPwd}).then(res => {
        this.inOnLineCinema(res);
      })
    }
  }
}
</script>

<style scoped>
body {
  height: 100vh;
  margin: 0;
  background: rgb(19, 24, 17);
  background: radial-gradient(
      circle,
      rgba(19, 24, 17, 1) 50%,
      rgba(0, 0, 0, 1) 100%
  );
  color: gray;
  font-family: system-ui;
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

p {
  position: absolute;
  top: 25%;
}

.pumpkin {
  display: inline-block;
  width: 20vmin;
  aspect-ratio: 1.1/1;
  background: /*    radial-gradient(60% 125% at 50% 50%, rgba(255,255,255,.1) 50%, transparent 60%), */ #F60;
  text-align: center;
  position: relative;
  border-radius: 40%;
  filter: drop-shadow(5px 5px black) drop-shadow(-5px 5px black) drop-shadow(5px -5px black) drop-shadow(-5px -5px black);
  transition: 2s;
}

.rotate .pumpkin {
  transform: rotate(1440deg);
}

.pumpkin:before {
  content: "";
  width: 20%;
  height: 15%;
  background: saddlebrown;
  position: absolute;
  top: -15%;
  left: 40%;
}

.pumpkin:after {
  content: "";
  width: 30%;
  height: 15%;
  background: forestgreen;
  position: absolute;
  top: -15%;
  left: 15%;
  border-radius: 0 1rem 0 1rem;
}

.feature {
  /*  outline: 1px solid white; */
  width: 100%;
  height: 33%;
  display: block;
}

.eyes_one {
  background: radial-gradient(circle at 33% 100%, black 20%, transparent 21%),
  radial-gradient(circle at 66% 100%, black 20%, transparent 21%);
}

.eyes_two {
  background: conic-gradient(transparent 0deg 145deg, black 145deg 215deg, transparent 215deg);
  background-size: 50% 100%;
  /*  transform: translateY(50%); */
}

.eyes_three {
  background: radial-gradient(20% 50% at 33% 75%, black 50%, transparent 50%),
  radial-gradient(20% 50% at 66% 75%, black 50%, transparent 50%);
}

.nose_one {
  background: conic-gradient(transparent 0deg 145deg, black 145deg 215deg, transparent 215deg);
  transform: translateY(-25%);
}

.nose_two {
  background: conic-gradient(transparent 0deg 145deg, black 145deg 215deg, transparent 215deg);
  transform: translateY(25%) scaleY(-1);
}

.nose_three {
  background: radial-gradient(circle at 50% 100%, black 20%, transparent 21%);
  transform: translateY(-25%);
}

.mouth_one {
  background: radial-gradient(50% 25% at 50% 85%, #F60 50%, transparent 55%),
  black;
  width: 50%;
  margin: 0 auto;
  transform: translateY(-10%);
  border-radius: 0 0 50% 50%;
}

.mouth_two {
  background: black;
  width: 75%;
  height: 20%;
  margin: 0 auto;
  transform: translateY(10%);
  border-radius: 0 0 2rem 2rem;
  clip-path: polygon(
      0% 0%,
      25% 0%,
      35% 25%,
      45% 0%,
      55% 0%,
      65% 25%,
      75% 0%,
      100% 0%,
      100% 100%,
      0% 100%
  );
}

.mouth_three {
  background: black;
  width: 75%;
  height: 20%;
  margin: 0 auto;
  transform: translateY(0%) scaleY(-1);
  border-radius: 2rem;
  clip-path: polygon(
      0% 0%,
      25% 0%,
      35% 25%,
      45% 0%,
      55% 0%,
      65% 25%,
      75% 0%,
      100% 0%,
      100% 100%,
      0% 100%
  );
}
</style>